<template>   
 <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h2 class="text-primary">
            Respondamos algunas preguntas
          </h2>
          <b-card-text class="mb-2">
            o elija una categoría para encontrar rápidamente la ayuda que necesita
          </b-card-text>

          <!-- form -->
          <b-form class="faq-search-input">
            <b-input-group>
              <b-form-input v-model="filter" placeholder="Buscar pregunta frecuente..." @input="setQueryFilter(filter)"  />
              <b-input-group-append is-text style="cursor:pointer;" @click="clearInputSearch">
                 <b-icon icon="arrow-counterclockwise"  v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-tooltip.hover.top="'Limpiar búsqueda'"></b-icon>
              </b-input-group-append>
            </b-input-group>
                                  
          </b-form>
          <!-- form -->        
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <div v-if="!isLoadingQuestions">      
      <section id="faq-tabs" v-if="questionsByCategory.length">
        <b-tabs
          vertical
          content-class="col-12 col-md-8 col-lg-9"
          pills
          nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12"
          nav-class="nav-left"
        >   
          <!-- payment tab -->        
          <b-tab
            v-for="(category, index) in categories" :key="index"
            :active="!index"
            @click="setCategory(category)"
          >
            <!-- title -->
            <template #title>
              <feather-icon
                icon="InfoIcon"
                size="18"
                class="mr-1"
              />
              <span class="font-weight-bold">{{ category.title }}</span>
            </template>
            <FaqQuestionAnswer :options="questionsByCategory" :category="category" />
          </b-tab>
          <!--/ payment tab -->

          <!-- sitting lady image -->
          <template #tabs-end>
            <b-img
              fluid
              :src="require('@/assets/images/illustration/faq-illustrations.svg')"
              class="d-none d-md-block mt-auto"
            />
          </template>
          <!--/ sitting lady image -->
        </b-tabs>
      </section>
      <b-alert show variant="danger"  class="mt-2" v-else>
        <div class="alert-body text-center"> 😣 <span>No se encontró algo relacionado a su búsqueda</span></div>
      </b-alert>    
    </div>
    <div class="text-center" v-if="isLoadingQuestions">
      <b-alert show variant="info">
        <div class="alert-body text-center"> <span>Cargando preguntas</span></div>
        <b-spinner class="mb-2" label="Loading..." variant="success"/>
      </b-alert> 
                               
    </div> 
    

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>¿Todavía tienes una pregunta?</h2>
          <b-card-text class="mb-3">
            Si no puede encontrar una pregunta en nuestras preguntas frecuentes, siempre puede contactarnos. ¡Te responderemos a la brevedad!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h4>+52 984 -80- 44 -226 </h4>
            <span class="text-body">¡Siempre estaremos felices de ayudar!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h4>rsantos@thefives.com.mx</h4>
            <span class="text-body">Igual puede solicitar ayuda en este correo</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import FaqQuestionAnswer from '@/modules/faq/components/FaqQuestionAnswer'
import Ripple from 'vue-ripple-directive'
import { VBTooltip,} from 'bootstrap-vue'
export default {
  components:{
    FaqQuestionAnswer
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      filter: '' 
    }
  },
  async created() {
    await this.fetchFaq()
  },
  computed:{
    ...mapState('appConfig',['layout']),
    ...mapState('faq',['categories', 'query','isLoadingQuestions']),   
    ...mapGetters('faq',['questionsByCategory']),
    textPrimary(){
      return this.layout.skin === 'light' ? 'text-primary-light': 'text-primary-dark'    
    }
  },
  methods: {
    ...mapActions('faq',['fetchFaq']),
    ...mapMutations('faq',['setCategorySelected','setQueryFilter']),
    setCategory( category ){
      this.setCategorySelected( category )
      this.setQueryFilter('')
      this.filter = ''
    },
    clearInputSearch()  {
      if( this.filter != '' ){
        this.setQueryFilter('')
        this.filter = ''
      }
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
<style scoped>
.text-primary-light {
  color: #333366;
}

.text-primary-dark {
  color: #ffffff;
}
</style>